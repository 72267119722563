import { FC } from "react";
import { Accordion } from "react-bootstrap";
import cn from "classnames";

import TransactionsContext from "../../../transactions.context";
import { Transaction, TransactionCategoryIds } from "../../../types";
import Body from "./body";
import Header from "./header";
import classes from "./transaction-item.module.scss";

type PropsTypes = {
  transaction: Transaction;
  handleOpenEditTransaction?: (transactionId: number, transactionCategoryId: TransactionCategoryIds) => void;
  handleOpenConfirmTransaction?: (transaction: Transaction) => void;
  handleOpenDeleteTransaction?: (transactionId: number) => void;
  handleOpenDeleteCapitalIncrease?: (capitalIncreaseId: number) => void;
  handleOpenRollbackTransaction?: (transaction: Transaction) => void;
  className?: string;
  importView?: boolean;
  isMini?: boolean;
};

const TransactionItem: FC<PropsTypes> = ({ className, isMini, importView, ...props }) => {
  const isPortfolioView = TransactionsContext.useStoreState((state) => state.isPortfolioView);

  const eventKey = props.transaction.transactionId.toString();

  return (
    <Accordion flush className={cn("w-100", className)}>
      <div className={cn(classes.headerContainer, { [classes.headerBorder]: isPortfolioView })}>
        <div className="d-flex">
          <Header {...props} eventKey={eventKey} isMini={isMini} />
        </div>
        <Accordion.Collapse eventKey={eventKey} className={cn(classes.container, "pb-3 pt-1")}>
          <Body
            eventKey={eventKey}
            transaction={props.transaction}
            importView={importView}
            contextMenuHandlers={{
              handleOpenConfirmTransaction: props?.handleOpenConfirmTransaction,
              handleOpenEditTransaction: props?.handleOpenEditTransaction,
              handleOpenDeleteTransaction: props?.handleOpenDeleteTransaction,
              handleOpenRollbackTransaction: props?.handleOpenRollbackTransaction,
            }}
          />
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default TransactionItem;
