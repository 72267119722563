import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { isNil } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, P, Tag, Ui } from "common/components/atoms";
import GuideElementsIds from "common/guides/guide-configs/guide-elements-ids";
import useGuideAddCompanyValuation2 from "common/guides/guide-hooks/add-company-valuation/use-guide-add-company-valuation-2";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { CloseIcon, InformationCircleIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import EditTaxableValue from "./components/EditTaxableValue/EditTaxableValue";
import EditValuation from "./components/EditValuation/EditValuation";
import RemoveTaxableValueModal from "./components/RemoveTaxableValueModal/RemoveTaxableValueModal";
import RemoveValuationModal from "./components/RemoveValiationModal/RemoveValuationModal";
import ValuationHistory from "./components/ValuationHistory/ValuationHistory";
import ValuationOverview from "./components/ValuationOverview/ValuationOverview";
import classes from "./valuation.module.scss";
import ValuationContext from "./ValuationContext";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.valuation"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const Content: FC = () => {
  useDocumentTitleUpdate(t("title"));
  const { companyId } = useParams<{ companyId: string }>();
  const { hasFullAccess } = useFeatures(FEATURES.valuation);
  const [isNoSharesSectionVisible, setIsNoSharesSectionVisible] = useState<boolean>(false);

  const valuation = useStoreState((store) => store.company.valuation);
  const { getValuationThunk } = useStoreActions((actions) => actions.company);

  const isLoading = ValuationContext.useStoreState((state) => state.isLoading);
  const { setIsValuationEdit, setIsTaxableValueEdit } = ValuationContext.useStoreActions((actions) => actions);

  const handleEditTaxableValue = () => {
    setIsTaxableValueEdit(true);
  };

  const handleEditValuationOpen = () => {
    moveNext();
    setIsValuationEdit(true);
  };

  useEffect(() => {
    if (!isNil(valuation) && valuation.issuedShares === 0) {
      setIsNoSharesSectionVisible(true);
    }
  }, [valuation]);

  useEffect(() => {
    if (companyId) {
      getValuationThunk(Number(companyId));
    }
  }, [companyId, getValuationThunk]);

  const { moveNext } = useGuideAddCompanyValuation2();

  return (
    <PageContent data-testid="company-valuation-page">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>

        <div>
          <Button
            isFocusDisabled
            variant="tertiary"
            onClick={handleEditTaxableValue}
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          >
            {t("updateTaxableValue")}
          </Button>
          <Button
            id={GuideElementsIds.EditValuationBtn}
            isFocusDisabled
            className="ms-3"
            data-testid="valuation-open-edit-test-id"
            onClick={handleEditValuationOpen}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            isDisabled={!hasFullAccess}
          >
            {t("updateVluation")}
          </Button>
        </div>
      </PageContent.Header>

      {isNoSharesSectionVisible ? (
        <div className={cn("p-3 mb-3 d-flex", classes["no-shares"])}>
          <InformationCircleIcon width={24} height={24} />

          <div className="ms-2">
            <Ui.m bold className="mb-1">
              {t("addMoreInfoToSeeValues")}
            </Ui.m>

            <P.s>{t("moreValuesDescription")}</P.s>
          </div>

          <CloseIcon
            className={classes["close-icon"]}
            onClick={() => {
              setIsNoSharesSectionVisible(false);
            }}
          />
        </div>
      ) : null}

      <LoaderContainer loading={isLoading}>
        <ValuationOverview />

        <ValuationHistory />

        <EditValuation />
        <RemoveValuationModal />
        <RemoveTaxableValueModal />
        <EditTaxableValue />
      </LoaderContainer>
    </PageContent>
  );
};

const CompanyValuationPage: FC = () => {
  return (
    <ValuationContext.Provider>
      <Content />
    </ValuationContext.Provider>
  );
};

export default CompanyValuationPage;
