import { FC, MouseEventHandler, ReactNode, useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FormikValues, useFormik } from "formik";
import { isNil } from "ramda";
import * as Yup from "yup";

import {
  Button,
  ChecksGroup,
  DatePicker,
  Divider,
  P,
  SlidePanel,
  TextArea,
  TextField,
  Ui,
} from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { URL_VALIDATION_PATTERN } from "common/utils/validators";
import { useStoreActions, useStoreState } from "store/store";
import { ValuationSingle } from "store/types";
import { createTranslation } from "translation/helpers";

import ValuationContext from "../../ValuationContext";

const [t, validationTranslation] = [createTranslation("pages", "company.valuation"), createTranslation("validation")];

const EditValuation: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const fNumber = useFormatNumbers();
  const { currencySymbol } = useCurrency();

  const valuation = useStoreState((state) => state.company.valuation);
  const isLoading = ValuationContext.useStoreState((state) => state.isLoading);
  const isValuationEdit = ValuationContext.useStoreState((state) => state.isValuationEdit);
  const { setIsLoading, setIsValuationEdit } = ValuationContext.useStoreActions((actions) => actions);

  const { getValuationThunk } = useStoreActions((actions) => actions.company);

  const VALIDATION_SCHEMA = useMemo(
    () =>
      Yup.object().shape({
        valuation: Yup.number().min(1, "Valuation must be positive value").required(validationTranslation("required")),
        validFrom: Yup.string().required(validationTranslation("required")),
        title: Yup.string().required(validationTranslation("required")),
        referenceUrl: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
      }),
    []
  );

  const initialValues = useMemo<Partial<Omit<ValuationSingle, "id">>>(
    () => ({
      valuation: undefined,
      editor: "",
      validFrom: "",
      title: "",
      description: "",
      referenceUrl: undefined,
      notifyShareholders: false,
      comment: "",
    }),
    []
  );

  const { values, errors, touched, resetForm, handleChange, setFieldValue, handleSubmit, handleBlur } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
  });

  const isError = errors.valuation && touched.valuation;

  const closeOffCanvas = useCallback(() => {
    resetForm();
    setIsValuationEdit(false);
  }, [resetForm, setIsValuationEdit]);

  async function submitHandler(values: FormikValues) {
    setIsLoading(true);

    try {
      const request = await axios.post<{ id: number }>("/api/company/valuation", {
        ...values,
        id: Number(companyId),
      });

      if (request.status === 200) {
        const getValuation = await getValuationThunk(Number(companyId));

        if (!isNil(getValuation)) {
          closeOffCanvas();

          notify(
            t("notify.valuationSuccessUpdated"),
            true,
            "success",
            10000,
            false,
            "top-center",
            "valuation-edit-toast",
            (() => {
              return (
                <div className="d-flex">
                  <Ui.m>
                    {t.el("notify.valuationSuccessUpdated", {
                      values: {
                        shareValue: fNumber(getValuation.sharePrice, "sharePrice"),
                        valuation: fNumber(values.valuation, "value"),
                      },
                      components: [<strong key={1} />],
                    })}
                  </Ui.m>
                </div>
              );
            }) as unknown as ReactNode
          );
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <SlidePanel data-testid="offcanvas-container-test-id" show={isValuationEdit} onHide={closeOffCanvas}>
        <SlidePanel.Header title={t("updateCompanyValuation")} onHide={closeOffCanvas} />
        <SlidePanel.Section title={t("valuationDetails")}>
          <Row>
            <Col>
              <TextField
                type="number"
                isDisabled={isLoading}
                label={t("companyValuation")}
                placeholder={fNumber(valuation?.latestValuation, "value", { hideCurrencySymbol: true })}
                value={String(values.valuation)}
                error={errors.valuation}
                isTouched={touched.valuation}
                onChange={handleChange("valuation")}
                iconRight={isError ? undefined : currencySymbol}
                onBlur={handleBlur("valuation")}
              />
            </Col>
            <Col>
              <DatePicker
                isDateOnlyString
                isDisabled={isLoading}
                placeholder="dd.mm.yyyy"
                error={errors.validFrom}
                isTouched={touched.validFrom}
                data-testid="valuation-datepicker-test-id"
                date={values?.validFrom ? new Date(values?.validFrom) : undefined}
                label={t("dateOfNewValuation")}
                onChange={(date) => {
                  const currentDate = new Date();

                  if (new Date(date) > currentDate) {
                    notify("You can't set date in future", true, "error", 5000, true);

                    return;
                  }

                  setFieldValue("validFrom", date);
                }}
                onBlur={handleBlur("validFrom")}
              />
            </Col>
          </Row>

          <P.m className="mt-4 fw-500" style={{ color: scssVariables.primary1 }}>
            {t("currentValuation", {
              valuation: `${fNumber(valuation?.latestValuation || 0)}`,
            })}
          </P.m>
        </SlidePanel.Section>

        <SlidePanel.Section title={t("describeEvent")} isDividerVisible={false}>
          <P.m className="mb-3">{t("describeDescription")}</P.m>

          <TextField
            type="text"
            className="mb-3"
            isDisabled={isLoading}
            value={values.title}
            label={t("titleInput")}
            data-testid="valuation-title-test-id"
            error={errors.title}
            isTouched={touched.title}
            onChange={handleChange("title")}
          />

          <TextArea
            isOptional
            isDisabled={isLoading}
            label={t("description")}
            value={values.description}
            placeholder={t("descriptionPlaceholder")}
            error={errors.description && touched.description ? errors.description : undefined}
            onChange={handleChange("description")}
          />

          <TextField
            type="text"
            isOptional={true}
            className="mt-3"
            label={t("link")}
            isDisabled={isLoading}
            placeholder="http://"
            data-testid="valuation-referenceUrl-test-id"
            value={values.referenceUrl}
            error={errors.referenceUrl}
            isTouched={touched.referenceUrl}
            onChange={handleChange("referenceUrl")}
          />

          <Divider />

          <ChecksGroup className="mb-4">
            <ChecksGroup.Check
              label={t("checkEmailTitle")}
              description={t("checkEmailDescription")}
              checked={values.notifyShareholders}
              onChange={handleChange("notifyShareholders")}
            />
          </ChecksGroup>

          {values.notifyShareholders && (
            <TextArea
              className="mb-7"
              name="comment"
              isOptional
              value={values.comment}
              onChange={handleChange}
              label={t("additionalCommentTitle")}
            />
          )}

          <P.m className="mt-5">{t("updatingValueDescription")}</P.m>
        </SlidePanel.Section>

        <SlidePanel.Actions>
          <Button
            isDisabled={isLoading}
            data-testid="valuation-update-valuation-test-id"
            onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          >
            {t("updateVluation")}
          </Button>

          <Button
            className="ms-3"
            variant="secondary"
            isDisabled={isLoading}
            data-testid="valuation-cancel-valuation-test-id"
            onClick={closeOffCanvas}
          >
            {t("cancel")}
          </Button>
        </SlidePanel.Actions>
      </SlidePanel>
    </>
  );
};

export default EditValuation;
