import axios from "axios";
import { format } from "date-fns";
import { action, actionOn, createContextStore, thunk } from "easy-peasy";

import { transactionsApiBase } from "./transactions-service";
import {
  BuySellGetResponseDTO,
  BuySellPostResponseDTO,
  CapitalIncreaseFormGetDTO,
  CapitalIncreaseGetDTO,
  CheckResponseDTO,
  GetTransactionsDTO,
  ImportCaptableExcelPostResponseDTO,
  ImportTransactionsExcelPostResponseDTO,
  IssueSharesGetResponseDTO,
  NominalValueGetResponseDTO,
  SplitGetResponseDTO,
  SplitPostResponseDTO,
  SplitPreviewGetResponseDTO,
  TransactionsContextModel,
} from "./types";

const TransactionsContext = createContextStore<TransactionsContextModel>({
  // common data
  isLoading: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  isLocalLoading: false,
  setIsLocalLoading: action((state, payload) => {
    state.isLocalLoading = payload;
  }),

  isPortfolioView: false,
  setIsPortfolioView: action((state, payload) => {
    state.isPortfolioView = payload;
  }),

  elementIdToScroll: null,
  setElementIdToScroll: action((state, payload) => {
    state.elementIdToScroll = payload;
  }),

  // global transactions
  transactions: {
    transactions: [],
    transactedAtMax: "",
    nominalValue: 0,
    companyHasShares: false,
  },
  clearFormsAction: action(() => {}),
  clearFormsInternalActionOnNotUse: actionOn(
    (actions) => actions.clearFormsAction,
    (state) => {
      state.isConfirmModalOpen = false;
      state.isManageDocumentsFormOpen = false;

      state.isBuySellFormOpen = false;
      state.buySellTransactionDetails = null;

      state.isIssueSharesFormOpen = false;
      state.issueSharesTransaction = null;

      state.isSplitFormOpen = false;
      state.splitTransaction = null;

      state.isNominalValueFormOpen = false;
      state.nominalValueDetails = null;

      state.selectedTransaction = null;

      state.confirmedTransactionToRollback = null;
      state.transactionIdToDelete = null;
      state.capitalIncreaseIdToDelete = null;
    }
  ),
  setTransactions: action((state, payload) => {
    state.transactions = payload;
  }),
  transactionIdToDelete: null,
  setTransactionIdToDelete: action((state, payload) => {
    state.transactionIdToDelete = payload;
  }),
  confirmedTransactionToRollback: null,
  setConfirmedTransactionToRollback: action((state, payload) => {
    state.confirmedTransactionToRollback = payload;
  }),
  getTransactionsRequestThunk: thunk(async (actions, id) => {
    try {
      actions.setLoading(true);
      actions.setIsLocalLoading(true);

      const response = await axios.get<GetTransactionsDTO>(`${transactionsApiBase}/${id}`);
      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          actions.setTransactions({
            nominalValue: 0,
            companyHasShares: true,
            transactions: response.data,
            transactedAtMax: "2023-12-13T17:32:44.579",
          });
        } else {
          actions.setTransactions(response.data);
        }
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setLoading(false);
      actions.setIsLocalLoading(false);
    }
  }),
  deleteTransactionThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.delete(`${transactionsApiBase}/${id}`);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  rollbackTransactionThunk: thunk(async (actions, { companyId, id }) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/rollback/check`, {
        transactionId: id,
        companyId: companyId,
      });

      if (response.status === 200) {
        return response.data;
      }

      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),

  isManageDocumentsFormOpen: false,
  setIsManageDocumentsFormOpen: action((state, payload) => {
    state.isManageDocumentsFormOpen = payload;
  }),

  isConfirmModalOpen: false,
  setIsConfirmModalOpen: action((state, payload) => {
    state.isConfirmModalOpen = payload;
  }),

  isAddTransactionModalOpen: false,
  setIsAddTransactionModalOpen: action((state, payload) => {
    state.isAddTransactionModalOpen = payload;
  }),

  // selected transaction with Transaction type
  selectedTransaction: null,
  setSelectedTransaction: action((state, payload) => {
    state.selectedTransaction = payload;
  }),

  // buy/sell transaction
  isBuySellFormOpen: false,
  setIsBuySellFormOpen: action((state, payload) => {
    state.isBuySellFormOpen = payload;
  }),
  buySellTransactionDetails: null,
  setBuySellTransactionDetails: action((state, payload) => {
    state.buySellTransactionDetails = payload;
  }),
  checkBuySellTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/buy-sell/check`, data);

      if (response.status === 200) {
        return response.data;
      }

      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  getBuySellTransactionDetailsThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.get<BuySellGetResponseDTO>(`${transactionsApiBase}/buy-sell/${id}`);

      if (response.status === 200) {
        actions.setBuySellTransactionDetails(response.data);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postBuySellTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<BuySellPostResponseDTO>(`${transactionsApiBase}/buy-sell/add`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  editBuySellTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<BuySellPostResponseDTO>(`${transactionsApiBase}/buy-sell/edit`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),

  // capital increase transaction
  capitalIncreaseDetails: null,
  setCapitalIncreaseDetails: action((state, payload) => {
    state.capitalIncreaseDetails = payload;
  }),
  getCapitalIncreaseThunk: thunk(async (actions, id, { getState }) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.get<CapitalIncreaseGetDTO>(`${transactionsApiBase}/capital-increase/details/${id}`);
      if (response.status === 200) {
        const state = getState();

        actions.setCapitalIncreaseDetails({
          ...(state.capitalIncreaseDetails || {}),
          [response.data.transactionBundleId]: response.data,
        });
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  capitalIncreaseIdToDelete: null,
  setCapitalIncreaseIdToDelete: action((state, payload) => {
    state.capitalIncreaseIdToDelete = payload;
  }),
  getCapitalIncreaseGeneralThunk: thunk(async (actions, id) => {
    try {
      const response = await axios.get<CapitalIncreaseFormGetDTO>(`${transactionsApiBase}/capital-increase/${id}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }),
  postCapitalIncreaseGeneralThunk: thunk(async (actions, data) => {
    try {
      const response = await axios.post<CapitalIncreaseFormGetDTO>(`${transactionsApiBase}/capital-increase`, data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }),
  checkCapitalIncreaseThunk: thunk(async (actions, data) => {
    try {
      const response = await axios.post<CheckResponseDTO>(
        `${transactionsApiBase}/capital-increase/transactions/check`,
        data
      );

      if (response.status === 200) {
        return response.data;
      }

      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    }
  }),
  postCapitalIncreaseThunk: thunk(async (actions, data) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/capital-increase/transactions`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }),
  postConfirmCapitalIncreaseThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/capital-increase/confirm`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  deleteCapitalIncreaseThunk: thunk(async (actions, id) => {
    try {
      const response = await axios.delete(`${transactionsApiBase}/capital-increase/${id}`);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }),
  rollbackCapitalIncreaseThunk: thunk(async (actions, { companyId, id }) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/capital-increase/rollback/check`, {
        transactionBundleId: id,
        companyId: Number(companyId),
      });

      if (response.status === 200) {
        return response.data;
      }

      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),

  // issue shares transaction
  issueSharesTransaction: null,
  isIssueSharesFormOpen: false,
  setIsIssueSharesFormOpen: action((state, payload) => {
    state.isIssueSharesFormOpen = payload;
  }),
  setIssueSharesTransaction: action((state, payload) => {
    state.issueSharesTransaction = payload;
  }),
  checkIssueSharesTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/issue/check`, data);

      if (response.status === 200) {
        return response.data;
      }

      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  getIssueSharesTransactionThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.get<IssueSharesGetResponseDTO>(`${transactionsApiBase}/pending-issue/${id}`);

      if (response.status === 200) {
        actions.setIssueSharesTransaction(response.data);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postIssueSharesTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/pending-issue/add`, data);

      if (response.status === 200) {
        return response.data.transactionId;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postConfirmIssueSharesTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/pending-issue/confirm`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  editPendingIssueSharesTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/pending-issue/edit`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  editConfirmedIssueSharesTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/issue/edit`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),

  // nominal value transaction
  isNominalValueFormOpen: false,
  setIsNominalValueFormOpen: action((state, payload) => {
    state.isNominalValueFormOpen = payload;
  }),
  isNominalValueDataUpdated: false,
  setIsNominalValueDataUpdated: action((state, payload) => {
    state.isNominalValueDataUpdated = payload;
  }),
  nominalValueDetails: null,
  setNominalValueDetails: action((state, payload) => {
    state.nominalValueDetails = payload;
  }),
  getNominalValueDetailsThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.get<NominalValueGetResponseDTO>(`${transactionsApiBase}/nominal-value/${id}`);
      if (response.status === 200) {
        actions.setNominalValueDetails(response.data);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postConfirmNominalValueThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/nominal-value/confirm`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postChangeNominalValueThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/nominal-value/add`, data);

      if (response.status === 200) {
        actions.setIsNominalValueDataUpdated(true);

        return true;
      }
    } catch (e) {
      actions.setIsNominalValueDataUpdated(false);
      return false;
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),

  // split transaction
  splitTransaction: null,
  setSplitTransaction: action((state, payload) => {
    state.splitTransaction = payload;
  }),
  isSplitFormOpen: false,
  setIsSplitFormOpen: action((state, payload) => {
    state.isSplitFormOpen = payload;
  }),
  getSplitPreviewThunk: thunk(async (actions, { companyId, multiplier, date }) => {
    try {
      actions.setIsLocalLoading(true);

      const splitDate = format(date ? new Date(date) : new Date(), "yyyy-MM-dd HH:mm");

      const response = await axios.get<SplitPreviewGetResponseDTO>(
        `${transactionsApiBase}/split/snapshot-preview/${companyId}/${multiplier}/${splitDate}`
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  getSplitSnapshotThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.get<SplitPreviewGetResponseDTO>(`${transactionsApiBase}/split/snapshot/${id}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  getSplitTransactionThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.get<SplitGetResponseDTO>(`${transactionsApiBase}/split/${id}`);

      if (response.status === 200) {
        actions.setSplitTransaction(response.data);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postSplitTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post<SplitPostResponseDTO>(`${transactionsApiBase}/split/add`, data);

      if (response.status === 200) {
        return response.data.transactionId;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),
  postConfirmSplitTransactionThunk: thunk(async (actions, data) => {
    try {
      actions.setIsLocalLoading(true);

      const response = await axios.post(`${transactionsApiBase}/split/confirm`, data);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    } finally {
      actions.setIsLocalLoading(false);
    }
  }),

  // import transactions
  importFile: null,
  deleteExistingTransactions: false,
  currentWarnings: [],
  currentErrors: [],
  captablePreview: [],
  transactionsPreview: [],
  setImportFile: action((state, payload) => {
    state.importFile = payload;
  }),
  setDeleteExistingTransactions: action((state, payload) => {
    state.deleteExistingTransactions = payload;
  }),
  setCurrentWarnings: action((state, payload) => {
    state.currentWarnings = payload;
  }),
  setCurrentErrors: action((state, payload) => {
    state.currentErrors = payload;
  }),
  setCaptablePreview: action((state, payload) => {
    state.captablePreview = payload;
  }),
  setTransactionsPreview: action((state, payload) => {
    state.transactionsPreview = payload;
  }),
  postImportTransactionsExcel: thunk(async (actions, data) => {
    try {
      const formData = new FormData();
      formData.set("companyId", data.companyId.toString());
      formData.set("deleteExistingTransactions", data.deleteExistingTransactions.toString());
      formData.set("file", data.file);

      const response = await axios.post<ImportTransactionsExcelPostResponseDTO>(
        "/api/import/check-transactions",
        formData
      );

      if (response.status === 200) {
        actions.setCurrentWarnings(response.data.transactionWarningReasons);
        actions.setCurrentErrors(response.data.transactionFailedReasons);
        actions.setCaptablePreview(response.data.capTableChanges);
        actions.setTransactionsPreview(response.data.newTransactions);

        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }),

  postImportCaptableExcel: thunk(async (actions, data) => {
    try {
      const formData = new FormData();
      formData.set("companyId", data.companyId.toString());
      formData.set("file", data.file);

      const response = await axios.post<ImportCaptableExcelPostResponseDTO>("/api/import/check-cap-table", formData);

      if (response.status === 200) {
        actions.setCurrentWarnings(response.data.transactionWarningReasons);
        actions.setCurrentErrors(response.data.transactionFailedReasons);
        actions.setCaptablePreview(response.data.capTableChanges);

        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }),

  postConfirmImportTransaction: thunk(async (action, data) => {
    try {
      const formData = new FormData();
      formData.set("companyId", data.companyId.toString());
      formData.set("deleteExistingTransactions", data.deleteExistingTransactions.toString());
      formData.set("file", data.file);

      const response = await axios.post("/api/import/transactions", formData);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }),

  postConfirmImportCaptable: thunk(async (action, data) => {
    try {
      const formData = new FormData();
      formData.set("companyId", data.companyId.toString());
      formData.set("file", data.file);

      const response = await axios.post("/api/import/cap-table", formData);

      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }),
});

export default TransactionsContext;
