import React, { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { ModalProps } from "react-bootstrap/Modal";
import axios from "axios";
import { format } from "date-fns";
import { FormikValues, useFormik } from "formik";
import { isNil } from "ramda";
import * as Yup from "yup";

import Button from "common/components/atoms/Button/Button";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import Spinner from "common/components/atoms/Spinner/Spinner";
import TextField from "common/components/atoms/TextField/TextField";
import ToggleSwitch from "common/components/atoms/ToggleSwitch/ToggleSwitch";
import { H, P, Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";
import { postAdminSubscription } from "common/utils/functions";
import AdminContext from "pages/admin/AdminContext";
import { createTranslation } from "translation";

const validationTranslation = createTranslation("validation");

type AdminSubscriptionProps = {
  companyId: number;
  expiryDate: string;
  hasSubscription: boolean;
  quantityOfPaidAgreements: number;
};

const valSchema = () =>
  Yup.object().shape({
    hasSubscription: Yup.boolean(),
    expiryDate: Yup.string().when("hasSubscription", {
      is: true,
      then: Yup.string().required(validationTranslation("required")),
    }),
    quantityOfPaidAgreements: Yup.number()
      .min(0)
      .when("hasSubscription", {
        is: true,
        then: Yup.number().required(validationTranslation("required")),
      }),
  });

const ManageAdminSubscriptionModal: FC<ModalProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<AdminSubscriptionProps | null>(null);

  const manageSubscriptionId = AdminContext.useStoreState((state) => state.manageSubscriptionId);
  const setManageSubscriptionId = AdminContext.useStoreActions((actions) => actions.setManageSubscriptionId);
  const getCompaniesThunk = AdminContext.useStoreActions((actions) => actions.getCompaniesThunk);

  const initValues = useMemo(() => {
    return {
      hasSubscription: subscription?.hasSubscription,
      expiryDate: subscription?.expiryDate,
      quantityOfPaidAgreements: subscription?.quantityOfPaidAgreements,
    };
  }, [subscription?.expiryDate, subscription?.hasSubscription, subscription?.quantityOfPaidAgreements]);

  const { values, errors, touched, setFieldValue, handleSubmit, handleChange } = useFormik({
    initialValues: initValues,
    validationSchema: valSchema,
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  const handleChangeDate = useCallback(
    (date: string | Date) => {
      setFieldValue("expiryDate", date);
    },
    [setFieldValue]
  );

  const getSubscriptionRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      const request = await axios.get<AdminSubscriptionProps>(`/api/admin/subscription/${manageSubscriptionId}`);

      if (request.status === 200) {
        setSubscription(request.data);
      }
    } catch (e) {
      console.error({ e });
      setManageSubscriptionId(null);
    } finally {
      setIsLoading(false);
    }
  }, [manageSubscriptionId, setManageSubscriptionId]);

  const handleClose = () => {
    setManageSubscriptionId(null);
  };

  async function submitHandler(values: FormikValues) {
    try {
      const data = {
        companyId: Number(subscription?.companyId),
        hasSubscription: values.hasSubscription,
        expiryDate: format(new Date(values.expiryDate), "yyyy-MM-dd"),
        quantityOfPaidAgreements: values.quantityOfPaidAgreements,
      };

      const request = await postAdminSubscription(data);

      if (request.status === 200) {
        getSubscriptionRequest();
        getCompaniesThunk();
        setManageSubscriptionId(null);
      }
    } catch (e) {
      console.error({ e });
    }
  }

  useEffect(() => {
    if (manageSubscriptionId) {
      getSubscriptionRequest();
    }
  }, [getSubscriptionRequest, manageSubscriptionId]);

  return (
    <ModalInfo show={Boolean(manageSubscriptionId)} onHide={handleClose}>
      {isLoading ? (
        <Spinner />
      ) : !isNil(subscription) ? (
        <div className="text-start">
          <div className="d-flex align-items-center justify-content-between">
            <H.xs>Manage subscription</H.xs>

            <div>
              <Ui.xs className="mb-1">Subscription active</Ui.xs>
              <ToggleSwitch checked={values.hasSubscription} name="hasSubscription" onChange={handleChange} />
            </div>
          </div>

          <Ui.l bold className="mt-5">
            Incentive agreements
          </Ui.l>

          <P.m className="mt-2">How many stakeholders can hold incentive agreements in this company?</P.m>

          <TextField
            type="number"
            className="mt-3"
            name="quantityOfPaidAgreements"
            label="Enter number of stakeholders"
            error={errors.quantityOfPaidAgreements}
            isTouched={touched.quantityOfPaidAgreements}
            value={!values.hasSubscription ? 0 : values.quantityOfPaidAgreements}
            isDisabled={!values.hasSubscription}
            onChange={handleChange}
          />

          <div style={{ height: 1, backgroundColor: scssVariables.strokeMedium }} />

          <Ui.l bold className="mt-5">
            Subscription expiry
          </Ui.l>

          <P.m className="mt-2 mb-3">When should the subscription expire?</P.m>

          <DatePicker
            name="expiryDate"
            label="Select date"
            date={!values.hasSubscription ? new Date() : values.expiryDate}
            onChange={handleChangeDate}
            error={errors.expiryDate}
            isTouched={touched.expiryDate}
            isDisabled={!values.hasSubscription}
          />

          <div className="d-flex mt-8">
            <Button onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}>Update</Button>
            <Button variant="secondary" className="ms-3" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      ) : null}
    </ModalInfo>
  );
};

export default ManageAdminSubscriptionModal;
