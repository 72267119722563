import { FC, useCallback, useContext, useEffect } from "react";
import { AccordionContext } from "react-bootstrap";
import cn from "classnames";
import { defaultTo, isEmpty, isNil } from "ramda";

import { Button, P, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CommonFileIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../../transactions.context";
import { Transaction, TransactionStatus } from "../../../../types";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.transactions.changeNominalValueForm");

type PropsTypes = {
  transaction: Transaction;
};

const NominalValueItemBody: FC<PropsTypes> = ({ transaction }) => {
  const fNumber = useFormatNumbers(transaction.currencySymbol);
  const { activeEventKey } = useContext(AccordionContext);

  const isPortfolioView = TransactionsContext.useStoreState((state) => state.isPortfolioView);
  const isNominalValueDataUpdated = TransactionsContext.useStoreState((state) => state.isNominalValueDataUpdated);
  const { setIsNominalValueDataUpdated, getNominalValueDetailsThunk } = TransactionsContext.useStoreActions(
    (actions) => actions
  );

  const { setSelectedTransaction, setElementIdToScroll, setIsManageDocumentsFormOpen } =
    TransactionsContext.useStoreActions((actions) => actions);

  const isPending = transaction.statusId === TransactionStatus.Pending;

  const openManageDocumentsHandler = useCallback(() => {
    setSelectedTransaction(transaction);
    setIsManageDocumentsFormOpen(true);
    setElementIdToScroll(transaction?.transactionId ? String(transaction?.transactionId) : null);
  }, [setElementIdToScroll, setIsManageDocumentsFormOpen, setSelectedTransaction, transaction]);

  const getDetailsDataHandler = useCallback(async () => {
    try {
      await getNominalValueDetailsThunk(transaction.transactionId);
    } catch (e) {
      console.error({ e });
    } finally {
      if (isNominalValueDataUpdated) {
        setIsNominalValueDataUpdated(false);
      }
    }
  }, [getNominalValueDetailsThunk, isNominalValueDataUpdated, setIsNominalValueDataUpdated, transaction.transactionId]);

  useEffect(() => {
    if (isNominalValueDataUpdated) {
      getDetailsDataHandler();
    }
  }, [getDetailsDataHandler, isNominalValueDataUpdated, transaction.transactionId]);

  useEffect(() => {
    if (!isNil(activeEventKey)) {
      getDetailsDataHandler();
    }
  }, [activeEventKey, getDetailsDataHandler, transaction.transactionId]);

  return (
    <div className={cn("p-3", classes["container"])}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={classes["info-section"]}>
          <Ui.m bold className="mb-1">
            {t("updateBefore")}
          </Ui.m>

          <div className={classes["content"]}>
            <div>
              <Ui.xxl bold>{fNumber(transaction.sharePriceBefore, "sharePrice")}</Ui.xxl>
              <Ui.s>{t("nominalShareValue")}</Ui.s>
            </div>
          </div>
        </div>

        <ArrowRightIcon className={classes["icon"]} />

        <div className={classes["info-section"]}>
          <Ui.m bold className="mb-1">
            {t("updateAfter")}
          </Ui.m>

          <div className={classes["content"]}>
            <div>
              <Ui.xxl bold style={{ color: isPending ? scssVariables.information700 : scssVariables.positive700 }}>
                {fNumber(transaction.sharePrice, "sharePrice")}
              </Ui.xxl>
              <Ui.s>{t("nominalShareValue")}</Ui.s>
            </div>
          </div>
        </div>
      </div>

      {transaction?.description ? (
        <div className="mt-4">
          <P.s
            dangerouslySetInnerHTML={{
              __html: transaction?.description?.replace(/\r\n/g, "<br />"),
            }}
          />
        </div>
      ) : null}

      <div
        className={cn("mt-3 px-3 py-2", classes["documents"], {
          "d-none": isPortfolioView && isEmpty(defaultTo([], transaction?.files)),
        })}
      >
        {!isEmpty(defaultTo([], transaction?.files)) ? (
          <div className="d-flex flex-wrap">
            {defaultTo([], transaction?.files).map((el) => {
              return (
                <div
                  key={el.downloadId}
                  className={cn("d-flex align-items-center", classes["single-document"])}
                  onClick={() => {
                    openRemoteDocument(el?.downloadId);
                  }}
                >
                  <CommonFileIcon fontSize={24} className="me-2" />
                  <Ui.s>{el.fileName}</Ui.s>
                </div>
              );
            })}
          </div>
        ) : (
          <Button size="s" variant="tertiary" onClick={openManageDocumentsHandler} iconLeft={<PlusIcon />}>
            Add documents
          </Button>
        )}
      </div>
    </div>
  );
};

export default NominalValueItemBody;
